import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import {
  getInformationFromModule,
  getModuleById,
  getCurrentModuleId,
  getCurrentLocale,
  getPictoTypeFromModule,
  getSurvey,
  getCmi5Info,
  getFirstActivityNotDone,
  getIsFrelloEmployee,
  getUserHasNoAccountYet,
  selectSchoolLogo
} from '../js/selectors';
import {
  GET_SCHOOL_LOGO,
  GO_TO_FIRST_ACTIVITY,
  RESET_SURVEY_AND_GO_TO_FIRST_ACTIVITY,
  SEE_CORRECTION_MODULE,
  SET_CURRENT_LOCALE
} from '../js/actions';
import theme from '../js/styles/theme.style';
import { translate } from '../js/services/i18n';
import BaseButton from '../components/atoms/BaseButton';
import BaseLayout from '../components/templates/BaseLayout';
import CloseButton from '../components/atoms/CloseButton';
import CircularProgressBarForActivity from '../components/atoms/CircularProgressBarForActivity';

const useStyles = makeStyles({
  progressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px 0px'
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.FONT_FAMILY_BOLD,
    fontSize: 20,
    color: theme.BLACK_BLUE,
    fontWeight: '600'
  },
  CTA: {
    display: 'grid',
    gap: 16,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 64
  },
  iconText: {
    textAlign: 'center',
    fontFamily: theme.FONT_FAMILY_BOLD,
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.BLACK_BLUE,
    lineHeight: theme.LINE_HEIGHT
  }
});

const Index = ({
  currentModuleData,
  goToFirstActivity,
  resetAndGoToFirstActivity,
  restartMode,
  picto,
  description,
  duration,
  title,
  setCurrentLocal,
  currentLocale,
  resume,
  displayCloseButton,
  currentActivityIndex,
  moduleCompletionMode,
  seeCorrectionModule,
  currentSurvey,
  isFrelloEmployee,
  userHasNoAccountYet,
  schoolLogo
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (resume) {
      goToFirstActivity();
    }
  }, [resume]);

  const getModuleButtonText = () => {
    const isModuleTerminated = currentModuleData.isTerminated;
    switch (true) {
    case userHasNoAccountYet:
      return translate('button.start');
    case (currentSurvey.updatedAt !== currentSurvey.createdAt) && !isModuleTerminated :
      return translate('button.resume');
    case isModuleTerminated && moduleCompletionMode !== 'devoir':
      return translate('button.restart');
    case !isModuleTerminated && currentActivityIndex > 0:
      return translate('button.resume');
    case !isModuleTerminated:
      return translate('button.start');
    default:
      return translate('button.start');
    }
  };

  useEffect(() => {
    dispatch({ type: GET_SCHOOL_LOGO });
    if (moduleCompletionMode === 'evaluation') {
      goToFirstActivity();
    }
  }, []);

  if (moduleCompletionMode === 'evaluation') {
    return (<div></div>);
  }

  return (
    <BaseLayout
      navActions={<>
        {displayCloseButton && <CloseButton color={theme.BLACK_BLUE}/>}
        {isFrelloEmployee && !currentModuleData.isTerminated && currentActivityIndex > 0 && (
          <BaseButton
            text={translate('button.reset')}
            width={250}
            onClick={() => { resetAndGoToFirstActivity(); }}
            variant="contained"
          />
        )}
      </>}
      title={title}
      subtitle={description}
      logo={schoolLogo}
    >
      <div className={classes.progressWrapper}>
        <CircularProgressBarForActivity
          sqSize={176}
          strokeWidth={14}
          progressPercentage={currentModuleData.result / 100}
          src={picto}
          status={currentModuleData.status}
          moduleCompletionMode={moduleCompletionMode}
        />
      </div>

      {(!currentModuleData.isTerminated || !currentModuleData.status === 'waiting_for_correction') && (
        <Typography className={classes.duration} component="p">
          <AccessAlarmIcon style={{ color: theme.BLACK_BLUE, fontSize: 32, marginRight: 16 }}/>
          {duration} {translate('moduleOpening.minutes')}
        </Typography>
      )}

      {!restartMode &&
      (currentModuleData.isTerminated || currentModuleData.status === 'waiting_for_correction') &&
        moduleCompletionMode !== 'devoir' && !userHasNoAccountYet &&
        (
          <Typography style={{ fontSize: 20, fontWeight: 600, textAlign: 'center', color: theme.BLACK_BLUE, marginTop: 16 }}>
            {translate('moduleClosing.restartAttention')}
          </Typography>
        )}

      <div className={classes.CTA}>
        <BaseButton
          text={restartMode ? translate('button.restart') : getModuleButtonText()}
          width={250}
          onClick={() => {
            if (restartMode || currentModuleData.isTerminated) {
              resetAndGoToFirstActivity();
            } else {
              goToFirstActivity();
            }
            setCurrentLocal(currentLocale);
          }}
          variant="contained"
        />
        {currentModuleData.isTerminated && !userHasNoAccountYet &&
          currentModuleData.status !== 'waiting_for_correction' &&
          moduleCompletionMode !== 'devoir' && (
          <BaseButton
            text={translate('button.correction')}
            variant="outlined"
            onClick={() => seeCorrectionModule()}
          />
        )}
      </div>
    </BaseLayout>
  );
};

Index.getInitialProps = async (ctx) => {
  const { isServer, query: { moduleId } } = ctx;

  return {
    isServer,
    moduleId
  };
};

const mapDispatchToProps = dispatch => ({
  resetAndGoToFirstActivity: () => dispatch({ type: RESET_SURVEY_AND_GO_TO_FIRST_ACTIVITY }),
  goToFirstActivity: () => dispatch({ type: GO_TO_FIRST_ACTIVITY }),
  setCurrentLocal: (locale) => dispatch({ type: SET_CURRENT_LOCALE, payload: { locale } }),
  seeCorrectionModule: () => dispatch({ type: SEE_CORRECTION_MODULE })
});

const mapStateProperties = (state) => {
  const moduleId = getCurrentModuleId(state);
  const currentModule = getModuleById(state, moduleId);
  const currentSurvey = getSurvey(state);
  const moduleCompletionMode = state.ui.moduleCompletionMode;
  const picto = getPictoTypeFromModule(currentModule);
  const { description, name: title } = getInformationFromModule(state, currentModule);
  const resume = state.ui.resume;
  const currentLocale = getCurrentLocale(state);
  const { temps: duration } = currentModule || {};
  const isFrelloEmployee = getIsFrelloEmployee(state);
  const userHasNoAccountYet = getUserHasNoAccountYet(state);

  const result = currentSurvey.point
    ? Math.min(
      Math.round(
        currentSurvey.score / currentSurvey.point * 100
      ), 100
    )
    : 0;
  const isTerminated = currentSurvey.termine === 1;
  const status = currentSurvey.status;
  const { isCMI5Inter } = getCmi5Info(state);
  const currentActivityIndex = getFirstActivityNotDone(state);
  const restartMode = isTerminated && isCMI5Inter;
  const schoolLogo = selectSchoolLogo(state);

  return {
    currentModuleData: {
      result,
      isTerminated,
      status
    },
    restartMode,
    picto,
    description,
    duration,
    title,
    resume,
    currentLocale, // this triggers change detection to rerender with the right translations on state change
    displayCloseButton: moduleCompletionMode !== 'evaluation' && !userHasNoAccountYet,
    currentActivityIndex,
    moduleCompletionMode,
    currentSurvey,
    isFrelloEmployee,
    userHasNoAccountYet,
    schoolLogo
  };
};
export default connect(mapStateProperties, mapDispatchToProps)(Index);
