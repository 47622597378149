import * as React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from '../../js/styles/theme.style';

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    backgroundColor: theme.WHITE,
    alignItems: 'center',
    padding: 40,
    minHeight: '100vh'
  },
  nav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  header: {
    margin: 'auto',
    maxWidth: 600,
    textAlign: 'center'
  },
  title: {
    margin: '16px 0',
    fontSize: 36,
    fontFamily: theme.FONT_FAMILY_BOLD,
    color: theme.BLACK_BLUE,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  subtitle: {
    textAlign: 'center',
    fontFamily: theme.FONT_FAMILY_BOLD,
    fontSize: 24,
    color: theme.BLACK_BLUE,
    fontWeight: 600
  },
  main: {
    margin: 'auto',
    maxWidth: 600,
  },
  titleContainer:{
    display: 'flex',
    direction: 'row',
    justifyContent:'center',
    alignItems: 'center',
    gap: 30
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: "absolute",
    bottom: 24,
    left: 0,
  },
});

interface Props {
  navActions?: React.ReactElement;
  title?: string;
  subtitle?: string;
  logo?: string
  children?: React.ReactElement;
  footer?: React.ReactElement;
}

const BaseLayout: React.FC<Props> = ({
  navActions,
  title,
  subtitle,
  children,
  logo,
  footer
}) => {
  const classes = useStyles();

  return (<div className={classes.wrapper}>
    <nav className={classes.nav}>
      {navActions}
    </nav>

    <header className={classes.header}>
     {(logo || title) && <div className={classes.titleContainer}>
        {logo && 
          <img style={{maxHeight: '100px'}} src={`${logo}`}></img>
        }
       {title && <Typography className={classes.title} component="h1">
          {title}
        </Typography>}
      </div>
     }

      {subtitle && <Typography className={classes.subtitle} component="p">
        {subtitle}
      </Typography>}
    </header>

    <main className={classes.main}>
      {children}
    </main>

   {footer && <div className={classes.footer}>
      {footer}
    </div>}
  </div>
  );
};

export default BaseLayout;
